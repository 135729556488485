import logo from './logo.svg';
import './App.css';
import PostForm from './Components/PostForm';
import PostsSection from './Components/PostsSection';


const  App = () =>{
  

  return (
    <div className="App" style={ { display:"flex", justifyContent:"center", alignItems:"center", height:"100vh" } } >
      <div className="Content-Container" style={{ display:"flex", width:"1000px", height:"800px" }} >
        <PostsSection/>
        <PostForm/>
      </div>
      
    </div>
  );
}

export default App;
