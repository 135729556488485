import { Fragment } from "react";
import Post from "./Post";
import axios from "axios";
import { useState, useEffect } from "react";

const PostsSection = ()=>{
    const [posts, setPosts] = useState([]);
    

    async function postsRequest(){
        const response = await axios.get("https://serverless-api.asd908433289.workers.dev/posts");
        setPosts(JSON.parse("["+ response.data +"]"));
        
    }

    useEffect(()=>{
        postsRequest();
    },[]);


    return(
        <div className="PostsSection" style={{margin:"0rem", width:"50%", backgroundColor:"lightblue"}} >
            {posts.map((p,index)=> <Post key={index} title={p.postTitle} username={p.postUsername} content={p.postContent} ></Post> )}
        </div>
    );
}

export default PostsSection;