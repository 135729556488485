
import {  Button, Card } from "react-bootstrap";
const Post = (props)=>{

    return(
        <Card style={{border:"1px solid black", margin:"5px", padding:"3px"}} >
        <Card.Body >
          <Card.Title style={{fontWeight:"bold"}} >{props.title}</Card.Title>
          <Card.Text   >{props.content}</Card.Text>
         
          
          <Card.Subtitle><i>{props.username}</i></Card.Subtitle>
          
        </Card.Body>
      </Card>
    );
}

export default Post;