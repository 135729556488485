import {Form, Button} from 'react-bootstrap';
import classes from "../CSS/PostForm.module.css";
import { useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router";
const PostForm = ()=>{
    const [ postTitle, setPostTitle ] = useState("");
    const [ postContent, setPostContent ] = useState("");
    const [ postUsername, setPostUsername ] = useState("");
    const history = useHistory();
    
    const handlePostButton = async ()=>{

        if( postTitle.length <1  ){
            alert("Missing a title");
            
        }
        else if( postContent.length < 1 ){
          alert("Missing post content");
            
        }
        else if( postUsername.length <1 ){
          alert("Missing username");
            
        }
        else{
           
            
            const response = await axios.post("https://serverless-api.asd908433289.workers.dev/newPost", { postTitle: postTitle, postContent: postContent, postUsername: postUsername } );
            if( !response.data === "success"){
              alert("Failed to submit post to backend.");
              
            }
            else{
              alert("Successfully submitted post to backend.");
              history.go(0);
            }
        }
    }

    return (
        
          <Form style={{backgroundColor:"lightyellow", width:"50%"}} >
            <Form.Group className={classes.formGroup} >
              <Form.Label>Post Title: </Form.Label>
              <Form.Control className={classes.formControl}
                value={postTitle}
                onChange={(e)=>{ setPostTitle(e.target.value); }}
              />
            </Form.Group>
  
            <Form.Group className={classes.formGroup} >
              <Form.Label>Post Content: </Form.Label>
              <Form.Control className={classes.formControl}
                as="textarea"
                style={{height:"300px"}}
                value={postContent}
                onChange={(e)=>{setPostContent(e.target.value);}}
              />
            </Form.Group>
  
            <Form.Group className={classes.formGroup}>
              <Form.Label>Username: </Form.Label>
              <Form.Control className={classes.formControl}
                value={postUsername}
                onChange={(e)=>{setPostUsername(e.target.value);}}
              />
            </Form.Group>
  
  
            <Form.Group className={classes.formGroup} style={{justifyContent:"center", paddingLeft:"5%"}}>
              <Button onClick={handlePostButton} style={{width:"100px"}} >Post</Button>
            </Form.Group>
  
            
          </Form>
      
      );
}

export default PostForm;